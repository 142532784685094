import React from "react"

import { StickyElement, isBrowser } from "@lesmills/gatsby-theme-common"
import Toast from "./Toast"

type ToastProps = {|
  title: string,
  prismicData: Object,
  getTrialUnitText: () => void,
|}

const StickyToast = ({ prismicData }: ToastProps) => {
  const {
    start_free_trial_button_label = {},
    unlimited_access_text = {},
    see_prices_button_label = {},
  } = prismicData

  return (
    <StickyElement offsetTop={isBrowser && window && window.innerHeight}>
      {({ isSticky }) => {
        return (
          isSticky && (
            <Toast
              title={unlimited_access_text}
              primaryLink={start_free_trial_button_label}
              secondaryLink={see_prices_button_label}
              classNames={{
                wrapper:
                  "fixed bottom-0 md:top-0 md:bottom-auto animation-fade-in md:hidden z-100",
              }}
              id="new-slp-banner"
            />
          )
        )
      }}
    </StickyElement>
  )
}

export default StickyToast
