// @flow
import React from "react"
import { formatRichTextLink } from "@lesmills/gatsby-theme-common"

type Props = {|
  title: string,
  classNames?: Object,
  primaryLink?: Object,
  secondaryLink?: Object,
  id?: string,
|}

const Toast = ({
  title,
  primaryLink = {},
  secondaryLink = {},
  classNames = { wrapper: "" },
  id = "",
}: Props) => {
  return (
    <div
      className={`bg-white flex flex-col justify-center items-center pt-13 pr-20 pb-13 pl-20 w-full z-50 shadow-sm md:shadow xs_md:flex-row ${classNames.wrapper}`}
      id={id}
    >
      <p
        className={
          "font-avenir-roman text-xxs text-center leading-none mb-12 mr-0 xs_md:mr-20 xs_md:mb-0 md:mb-12 md:mr-0"
        }
      >
        {title && title.text}
      </p>
      <div className="flex items-center mx-20 xs_md:items-start xs_md:mx-0">
        {formatRichTextLink(
          primaryLink && primaryLink.raw,
          "uppercase text-black bg-green-460 hover:text-white hover:bg-gray-970 cursor-pointer text-xs font-primary py-10 mr-15 text-center no-underline w-132 xs_md:w-150"
        )}
        {formatRichTextLink(
          secondaryLink && secondaryLink.raw,
          "uppercase bg-black border-green-460 text-green-460 cursor-pointer text-xs box-content py-10 font-primary border text-center no-underline w-132 xs_md:w-150"
        )}
      </div>
    </div>
  )
}

export default Toast
